import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import clsx from "clsx";

export default function Logo({ textColor, stack }) {
  return (
    <div
      className={clsx("flex h-20 items-center gap-4", {
        ["mb-4 flex-col md:mb-0 md:flex-row"]: stack,
      })}
    >
      <div className="relative mb-2 h-12 w-12">
        <StaticImage
          src="../../images/logo.png"
          alt="Cultiware Logo"
          layout="fullWidth"
          backgroundColor="transparent"
          loading="eager"
          fadeIn={false}
          placeholder="none"
        />
      </div>
      <div
        className={`font-heading text-3xl ${
          textColor ? textColor : "text-textBlack"
        }`}
      >
        cultiware
      </div>
    </div>
  );
}
