import React from "react";
import { Link } from "gatsby";
import { Trans } from "react-i18next";
import Logo from "./Logo";
import { MenuIcon } from "@heroicons/react/solid";
import LanguageSelector from "./LanguageSelector";
import Socials from "./Socials";
import useScrollPosition from "@react-hook/window-scroll";
import clsx from "clsx";

export const primaryLinks = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "Solutions",
    link: "/solutions/",
  },
  {
    text: "Services",
    link: "/services/",
  },
  {
    text: "Our Mission",
    link: "/our-mission/",
  },
];

export const secondaryLinks = [
  {
    text: "Contact Us",
    link: "/contact-us/",
  },
  {
    text: "Partners",
    link: "/partners/",
  },
  /*{
    text: "Support",
    link: "/contact-us",
  },*/
];

const isBrowser = () => typeof window !== "undefined";

function SecondaryNav() {
  const path = isBrowser() && window.location.pathname;
  const activeClass = "border-r-[3px] opacity-100 text-menuBlack border-solid border-r-primaryBlue";

  return (
    <div className="flex hidden flex-row justify-end pt-4 lg:flex">
      <ul className="mr-8 flex flex-row space-x-6">
        {secondaryLinks.map(({ link, text }) => {
          const active = path === link || path === `/fr${link}`;
          return (
            <li key={link}>
              <Link
                to={link}
                className={clsx("z-[999] px-4 text-sm opacity-50 hover:text-menuBlack", {
                  [activeClass]: active,
                })}
              >
                <Trans>{text}</Trans>
              </Link>
            </li>
          );
        })}
      </ul>
      <LanguageSelector />
    </div>
  );
}

function PrimaryNav() {
  const path = isBrowser() && window.location.pathname;
  const scrollY = useScrollPosition(60);
  const activeClasses = "border-r-[3px] opacity-100 text-menuBlack border-solid border-r-primaryBlue";

  return (
    <div className="flex hidden flex-row items-center  gap-12 transition-all lg:flex">
      <Logo />
      <ul className="flex flex-1 flex-row items-center justify-center space-x-6 ">
        {primaryLinks.map(({ link, text }) => {
          const active = path === link || path === `/fr${link}`;
          return (
            <li key={link} className="text-menuGray   hover:text-menuBlack">
              <Link
                to={link}
                className={clsx("px-4", {
                  [activeClasses]: active,
                })}
              >
                <Trans>{text}</Trans>
              </Link>
            </li>
          );
        })}
      </ul>
      <Socials />
      <LanguageSelector hidden={scrollY < 40} />
    </div>
  );
}

function MobileNav({ onOpen }) {
  return (
    <div className="block flex flex-row items-center justify-between px-8 lg:hidden">
      <Logo />
      <MenuIcon
        className="block h-8 w-8 cursor-pointer text-primaryBlue transition-all hover:scale-105 hover:bg-opacity-10"
        onClick={onOpen}
      />
    </div>
  );
}

export default function Nav({ openMobileMenu }) {
  return (
    <>
      <div className="w-full px-8 2xl:px-48">
        <SecondaryNav />
      </div>
      <nav className="sticky top-0 z-50 bg-white">
        <MobileNav onOpen={openMobileMenu} />
        <div className="mx-auto px-8 2xl:px-48">
          <PrimaryNav />
        </div>
        <div className="from- h-1 bg-gradient-to-r from-primaryGreen to-primaryBlue" />
      </nav>
    </>
  );
}
