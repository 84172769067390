import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";

export default function LanguageSelector({ spacingBottom, hidden }) {
  const { languages, language, originalPath, changeLanguage } = useI18next();

  function handleChange(event) {
    const { value } = event.target;
    changeLanguage(value);
  }

  return (
    <select
      name="languages"
      id="languages"
      value={language}
      onChange={handleChange}
      className={`${
        spacingBottom ? "mb-16" : "mb-0"
      } text-menuBlack outline-none ${hidden ? "invisible" : ""}`}
    >
      {languages.map((language, index) => (
        <option key={index} value={language}>
          {language.toUpperCase()}
        </option>
      ))}
    </select>
  );
}
