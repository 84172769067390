import React from "react";
import Logo from "./Logo";
import { Link } from "gatsby";
import { Trans } from "react-i18next";
import { XIcon } from "@heroicons/react/solid";
import { primaryLinks, secondaryLinks } from "./Nav";
import LanguageSelector from "./LanguageSelector";
import Socials from "./Socials";

export default function MobileNavMenu({ onClose }) {
  return (
    <div className="absolute top-0 z-[999] h-full w-full bg-white">
      <div className="flex flex-row items-center justify-between px-8">
        <Logo />
        <XIcon
          className="block h-8 w-8 cursor-pointer text-primaryBlue transition-all hover:scale-105 hover:bg-opacity-10"
          onClick={onClose}
        />
      </div>
      <div className="from- -mx-12 h-1 bg-gradient-to-r from-primaryGreen to-primaryBlue" />
      <ul className="mb-12 mt-8">
        {primaryLinks.map(({ link, text }) => (
          <Link key={link} activeClassName="opacity-100" to={link}>
            <li
              key={link}
              className="border-solid px-8 py-4 text-2xl font-bold"
            >
              <Trans>{text}</Trans>
            </li>
          </Link>
        ))}
        {secondaryLinks.map(({ link, text }) => (
          <Link key={link} activeClassName="opacity-100" to={link}>
            <li className="text-md px-8 py-4 font-light text-menuBlack">
              <Trans>{text}</Trans>
            </li>
          </Link>
        ))}
      </ul>
      <div className="px-8">
        <LanguageSelector spacingBottom />
        <Socials spacingBottom />
      </div>
    </div>
  );
}
