import React from "react";
import { Link } from "gatsby";

const links = [
  {
    id: "linkedin",
    name: "LinkedIn",
    url: "https://www.linkedin.com/company/cultiware",
    icon: "logo-linkedin",
  },
  {
    id: "instagram",
    name: "Instagram",
    url: "https://www.instagram.com/cultiware.ai",
    icon: "logo-instagram",
  },
  {
    id: "twitter",
    name: "Twitter",
    url: "https://twitter.com/cultiware",
    icon: "logo-twitter",
  },
  {
    id: "facebook",
    name: "Facebook",
    url: "https://www.facebook.com/cultiware.ca",
    icon: "logo-facebook",
  },
];

export default function Socials({ spacingBottom }) {
  return (
    <div className="z-50 w-48">
      <ul className="flex flex-row space-x-6 lg:justify-end">
        {links.map((link) => (
          <li key={link.name}>
            <a
              href={link.url}
              className="flex cursor-pointer items-center transition-all hover:scale-125"
              target="_blank"
            >
              <ion-icon name={link.icon} size="small" />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
