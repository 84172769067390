import React from "react";
import { Link } from "gatsby";
import { Trans, useTranslation } from "react-i18next";
import Logo from "./Logo";

const footerLinks = [
  {
    text: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    text: "Term of Use",
    link: "/terms-of-use",
  },
  {
    text: "Cookies Policy",
    link: "/cookie-policies",
  },
  /*{
    text: "Legal",
    link: "/",
  },
  {
    text: "Get in Touch",
    link: "/",
  },
  {
    text: "Support",
    link: "/",
  },*/
  {
    text: "Contact Us",
    link: "/contact-us",
  },
];

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-textBlack px-8 pb-2 pt-8 2xl:px-48">
      <div>
        <div className="mb-12 flex flex-col items-center justify-between  md:mb-8 md:flex-row md:items-start">
          <div className="mb-8 md:mb-0">
            <Logo textColor="text-white" stack />
            <p className="mt-8 text-center font-bold text-white md:mt-1 md:text-lg">
              {t("Take Control of Your Cultivation")}
            </p>
          </div>
          <div className="flex flex-row justify-end">
            <div className="mr-12 flex flex-col md:mr-20">
              {footerLinks.slice(0, 4).map(({ link, text }, index) => (
                <Link to={link} key={index} className="mb-4 text-sm text-white">
                  <Trans>{text}</Trans>
                </Link>
              ))}
            </div>
            <div className="flex flex-col">
              {footerLinks.slice(4).map(({ link, text }, index) => (
                <Link to={link} key={index} className="mb-4 text-sm text-white">
                  <Trans>{text}</Trans>
                </Link>
              ))}
              <a
                href="mailto:info@cultiware.com"
                className="mb-4 text-sm text-primaryGreen"
              >
                info@cultiware.com
              </a>
            </div>
          </div>
        </div>
        <p className="text-center text-sm text-white">{t("copyright")}</p>
      </div>
    </footer>
  );
}
