import React, { useState } from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import Helmet from "react-helmet";
import MobileNavMenu from "./MobileNavMenu";

export default function Layout({ children }) {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  if (openMobileMenu) {
    return <MobileNavMenu onClose={() => setOpenMobileMenu(false)} />;
  }

  return (
    <div>
      <Helmet>
        <script
          type="module"
          src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"
        />
      </Helmet>
      <Nav openMobileMenu={() => setOpenMobileMenu(true)} />
      <main className="flex-1">{children}</main>
      <Footer />
    </div>
  );
}
